import Vue from 'vue';
import App from './App.vue';
import VueGtag from 'vue-gtag';
import router from './router';
import store from './store';

// Mixins
import titleMixin from './mixins/titleMixin';
Vue.mixin(titleMixin);

/**
 * Importing Talwind Configuration which will be used globally
 */
const tailwindConfig = require('../tailwind.config.js');
Vue.prototype.$colours = Object.freeze(
  tailwindConfig.theme.extend.colors.brand
);

// Google Analytics with vue-gtag
Vue.use(VueGtag, {
  config: { id: 'G-M4JE4VD0WP' }
});

/**
 * Setting $http as a global instance
 */
import axios from 'axios';
Vue.prototype.$http = axios;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

/**
 * The holding page was mistakenly built as a PWA that installed a service
 * worker that cached the holding page for offline use. This was never removed
 * causing the holding page to still be the page loaded by browsers rather than
 * the correct site
 */
navigator.serviceWorker.getRegistrations().then(registrations => {
  for (let registration of registrations) {
    registration.unregister();
  }
});
